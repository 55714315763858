@import url('../src/assets/style/variable.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.font-aspekta-300 {
  font-family: var(--font-Aspekta-300) !important;
}
.font-aspekta-350 {
  font-family: var(--font-Aspekta-350) !important;
}
.font-aspekta-400 {
  font-family: var(--font-Aspekta-400) !important;
}
.font-aspekta-450 {
  font-family: var(--font-Aspekta-450) !important;
}
.font-aspekta-500 {
  font-family: var(--font-Aspekta-500) !important;
}
.font-aspekta-600 {
  font-family: var(--font-Aspekta-600) !important;
}
.font-aspekta-700 {
  font-family: var(--font-Aspekta-700) !important;
}
.cursor {
  cursor: pointer !important;
}
.main-container {
  display: flex;
  height: 100vh;
  flex-direction: row;
}
main {
  /* padding: 10px; */
  overflow: auto;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: #05162a;
  color: white;
  overflow-y: auto;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
  white-space: nowrap;
}
.bars {
  width: 30px;
}
.hide {
  display: none;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  border-left: 0.25rem solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  border-left: 0.25rem solid #c8a864;
  background: #031120;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.active {
  border-left: 0.25rem solid #c8a864 !important;
  background: #031120;
  color: #c8a864 !important;
}
.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: white;
  align-items: center;
  padding: 5px 10px;
  border-left: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
}
.VegaInput {
  border: none;
}
.VegaInput:focus {
  outline: none;
}

input[type='number'] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button,
input[type='number'] {
  -moz-appearance: textfield;
}
/* sx={{
  '.MuiPickersCalendarHeader-root': {},
  '.MuiPickersArrowSwitcher-root': {},
}} */
.MuiDayPicker-weekDayLabel {
  color: #031120 !important;
}
/* /// calender css  \\\\\ */
.react-calendar {
  border: none !important;
}
.react-calendar__tile--active {
  border: 1px solid #1047dc !important;
  background: white !important;
  color: #1047dc !important;
}
.react-calendar__navigation__label__labelText {
  font-family: var(--font-Aspekta-500) !important;
  font-size: 13px;
}
.react-calendar__month-view__weekdays__weekday {
  font-family: var(--font-Aspekta-700) !important;
  font-size: 9px;
  color: #676b76 !important;
}
.react-calendar__tile--active {
  border-radius: 6px;
}
.react-calendar__tile {
  font-family: var(--font-Aspekta-450) !important;
  font-size: 13px !important;
}
.react-calendar__tile--now {
  background: #1047dc !important;
  border-radius: 6px;
  color: white !important;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
/* //////////////  successfully icon \\\\\\\\\\\\\\\\\\ */

.main-container2 {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.check-container {
  width: 6.25rem;
  height: 7.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}
.check-container .check-background {
  width: 100%;
  height: calc(100% - 1.25rem);
  background: linear-gradient(to bottom right, #5de593, #41d67c);
  box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
    0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  transform: scale(0.84);
  border-radius: 50%;
  animation: animateContainer 0.75s ease-out forwards 0.75s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.check-container .check-background svg {
  width: 65%;
  transform: translateY(0.25rem);
  stroke-dasharray: 80;
  stroke-dashoffset: 80;
  animation: animateCheck 0.35s forwards 1.25s ease-out;
}
.check-container .check-shadow {
  bottom: calc(-15% - 5px);
  left: 0;
  border-radius: 50%;
  background: radial-gradient(closest-side, #49da83, transparent);
  animation: animateShadow 0.75s ease-out forwards 0.75s;
}

@keyframes animateContainer {
  0% {
    opacity: 0;
    transform: scale(0);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  25% {
    opacity: 1;
    transform: scale(0.9);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  43.75% {
    transform: scale(1.15);
    box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  62.5% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
  }
  81.25% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
  100% {
    opacity: 1;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
}
@keyframes animateCheck {
  from {
    stroke-dashoffset: 80;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes animateShadow {
  0% {
    opacity: 0;
    width: 100%;
    height: 15%;
  }
  25% {
    opacity: 0.25;
  }
  43.75% {
    width: 40%;
    height: 7%;
    opacity: 0.35;
  }
  100% {
    width: 85%;
    height: 15%;
    opacity: 0.25;
  }
}
/* ///////// error icon \\\\\\\\ */

.svg {
  width: 100px;
  height: 100px;
  transition: transform 0.5s ease-out;
}

.svg #red-exclamation {
  animation: bounce 0.5s ease-out forwards;
  transform-origin: center center;
}

.svg #red-exclamation .exclamation {
  opacity: 0;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: excl 1.5s ease forwards 0.5s;
}
.svg #red-exclamation .dot {
  opacity: 0;
  stroke-width: 30;
  stroke-linecap: round;
  animation: dot-fade 0.175s ease forwards 0.7s;
}

@keyframes excl {
  0% {
    stroke-dashoffset: 100;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}

@keyframes dot-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.6);
  }
  100% {
    opacity: 1;
    transform: scale(0.9);
  }
}
input:focus {
  outline: none;
}
.custom-tooltip {
  background-color: white;
  min-width: 100px;
  border-radius: 5px;
  font-size: 14px;
}
.custom-tooltip .header {
  color: #1047dc;
  background-color: lightgrey;
  padding: 5px 10px;
  border-bottom: 1px solid #1047dc;
  font-size: 14px;
}
.custom-tooltip ul {
  list-style-type: none;
  padding: 5px 10px;
}
.custom-tooltip ul .li {
  display: flex;
  align-items: center;
  color: #1047dc;
  background-color: white;
  padding: 5px 10px;
  border-bottom: 1px solid #1047dc;
}
.custom-tooltip span {
  font-size: 12px;
}

.custom-tooltip .dot {
  display: inline-block;
  width: 8px; /* Adjust the size as needed */
  height: 8px; /* Adjust the size as needed */
  border-radius: 50%; /* Creates a circular shape */
  margin-right: 5px; /* Adjust the spacing as needed */
}
/* .customTable .MuiTableRow-root td,
.customTable .MuiTableRow-root th {
  border-right: 1px solid;
}
.customTable .MuiTableRow-root:last-child td,
.customTable .MuiTableRow-root:last-child th {
  border-right: 'none';
} */
.customTable .MuiTableHead-root {
  padding: 5px 10px;
}
